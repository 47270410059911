<template>
  <div class="project">
    <Head :headdata="headdata"></Head>
    <div class="projectMain common-main">
      <h2 class="title_zh">
        {{ projectName  }}
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share/>
        </div>
      </h2>
      <h2 class="title_en">{{ projectSubName }}</h2>
      <div class="title_text min768" v-if="active  == 1">
        <p v-if="langText == 'En'">-创造有归属感的城市与乡村</p>
        <p v-if="langText != 'En'">Generate a sense of belonging in cities and counties</p>
        <br>
        <p>城乡策划以城乡为策划研究对象，涵盖城市、小镇、街区、村落、园区、景区等类型。</p><br>
        <p>
          bicc湾创期待通过对城乡的深刻洞察、体认与创意，以城乡策划者的角色，协助决策者<br class="max768">和建设者，树立本土自信、回归地域特质、延续在地文脉，助力城乡走向复兴之路。
        </p>
      </div>
      

      <div class="title_text" v-if="active  == 2">
        <p v-if="langText == 'En'">创造有温度的建筑空间</p>
        <p v-if="langText != 'En'">Build a space with warmth</p><br>
        <p>建筑策划以建筑或建筑群为策划研究对象，包括了建筑物、构筑物、建筑外部空间等类型。</p><br >
        <p>bicc湾创希望建筑不仅仅是 “建设工程”的容器，而是能将文化的记忆、生活的温度和持<br class="max768">续的生命力注入其里，在引导投资效益的最大化的同时，满足现代人居生活的价值观念、<br class="max768">经济模式与文化需求。</p>
      </div>

      <div class="title_text" v-if="active  == 3">
       <p v-if="langText == 'En'">创造有共鸣的主题乐园</p>
       <p v-if="langText != 'En'">Create a symphonic theme park</p><br>
       <p>乐园策划以乐园为策划研究对象，涵盖主题乐园、水乐园、无动力乐园、大型商业项目和<br class="max768">文创项目中的娱乐体验等类型。</p><br >
       <p>bicc湾创希望改变“有资源无品牌，有元素无整合，有文脉无主题，有潜力无驱动”的行<br class="max768">业现状，为之注入不仅仅是表象元素而是深入人心的文化体验，赋予乐园物理刺激之外的<br class="max768">精神撼动。</p>
      </div>

      <div class="search">
        <img @click="setSearch" class="searchImg" src="../assets/images/icon_search.png" alt />
        <input type="text" v-model="valueText" placeholder="按分类检索" />
        
      </div>
      <div class="navBox">
        <ul class="clearfloat bar">
          <li>
            <div class="pointer " :class="active == 0? 'active':''" @click="getLink('/project?type_id=0', true)" >全部</div>
          </li>
          <li>
            <div class="pointer " :class="active == 1? 'active':''" @click="getLink('/city?type_id=69', true)" >城乡策划</div>
          </li>
          <li>
            <div class="pointer " :class="active == 2? 'active':''" @click="getLink('/architecture?type_id=70', true)" >建筑策划</div>
          </li>
          <li>
            <div class="pointer " :class="active == 3? 'active':''"  @click="getLink('/park?type_id=71', true)" >乐园策划</div>
          </li>
          <!-- <li>
            <div class="pointer" @click="getLink('/about', false)" >地图</div>
          </li> -->
        </ul>
      </div>
      <div class="projectListsBox">
        <Projectlists ajaxUrl="/wc/proLists"></Projectlists>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import Projectlists from "@/components/projectlists.vue";
import share from "@/components/share.vue";
export default {
  name: 'projectview',
  data() {
    return {
      active: 0,
      headdata: this.$t("m.projectHeaddata5"),
      listsData: this.$t("m.sidebarList"),
      projectName: '',
      projectSubName: '',
      valueText:'',
    };
  },
  components: {
    Head,
    Projectlists,
    share
  },
 computed: {
    langText() {
      return this.$t("m.langText");
    },
  },
  watch: {
    $route: {
      handler(nv, ov) {
        const { top } = nv.query
        const { path } = nv
        if (path.indexOf('project') > -1) {
          this.active = 0
          this.headdata = this.$t("m.projectHeaddata5")
          this.projectName = this.$t("m.projectName")
          this.projectSubName = this.$t("m.projectSubName")
        } else if (path.indexOf('city') > -1) {
          this.active = 1
          this.headdata = this.$t("m.projectHeaddata6")
          this.projectName = this.$t("m.projectName1")
          this.projectSubName = this.$t("m.projectSubName1")
        } else if (path.indexOf('architecture') > -1) {
          this.active = 2
          this.headdata = this.$t("m.projectHeaddata7")
          this.projectName = this.$t("m.projectName2")
          this.projectSubName = this.$t("m.projectSubName2")
        } else if (path.indexOf('park') > -1) {
          this.active = 3
          this.headdata = this.$t("m.projectHeaddata8")
          this.projectName = this.$t("m.projectName3")
          this.projectSubName = this.$t("m.projectSubName3")
        }
        if (top && ov && ov.name && ( ov.name.indexOf('project') > -1 || ov.name.indexOf('architecture') > -1 || ov.name.indexOf('park') > -1 || ov.name.indexOf('city') > -1)  ) {
          this.$nextTick( () => {
            document.body.scrollTop = document.documentElement.scrollTop = top
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted(){
  },
  methods: {
    getLink(url, isJoin) {
      if (isJoin) {
        let top = document.documentElement.scrollTop || document.body.scrollTop;
        let u = url + `&top=${top}`
        this.$router.push(u)
      } else {
        this.$router.push(url)
      }
    },
    setSearch(){
      if(!this.valueText){
        return 
      }
      this.$router.push({ name: 'search', params: { ajaxUrl:"/wc/proLists", value: this.valueText }})
    },
  },
 
};
</script>

<style lang="scss" scoped>
.project {
  height: 100%;
  width: 100%;
  .active {color: rgba(233, 128, 35, 1);}
  .projectMain {
    width: 100%;
    
    padding: 78px 200px 0 200px;
    .navBox {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #ccc;
      ul {
        li {
          width: 25%;
          float: left;
          text-align: left;
          font-size: 20px;
          height: 80px;
          line-height: 80px;
          a{
            color: #808080;
            font-weight: bold;
          }
          .router-link-exact-active, 
          .router-link-active{
            color: #D38022;
          }
        }
      }
    }
    .title_zh {
      color: #000;
      font-size: 34px;
      cursor: pointer;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      cursor: pointer;
      // margin-bottom: 60px;
      font-size: 24px;
    }
    .title_text {
      font-size: 18px;
      color: #000;
      margin-top:30px;
      line-height: 1.5;
    }
    .search {
      margin-top: 60px;
      width: 100%;
      border-bottom: 1px solid #ccc;
      input {
        width: 90%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding-left: 2px;
      }
      .searchImg {
        width: 40px;
        height: 40px;
        padding: 12px;
        float: left;
      }
    }
    .projectListsBox {
      width: 100%;
    }
  }
}
@media (max-width: 768px) { 
    .title_text {
      font-size: 13px !important;
    }
   .bar {
     li {
        div {
          font-size: 14px;
        }
       
     }
   }
  }

</style>